<template>
  <div>
    <v-row>
      <v-col>
        <h2 class="page-title mb-8">{{ $t("Educational History") }}</h2></v-col
      >
      <v-col :class="currentAppLocale == 'ar' ? 'text-left' : 'text-right'">
        <!-- <v-btn >{{ $t("Print") }}</v-btn> -->
        <!-- <v-btn @click="print" class="main-color-back" v-if="is_active">
          <v-icon class="mr-3"   :title="$t('Print')">print</v-icon>
          {{$t('Report Card')}}
        </v-btn>
        
        <v-btn @click="printDetailes" class="main-color-back" v-if="is_active">
          <v-icon class="mr-3" :title="$t('Print Details')">print
          </v-icon> {{ $t('Details') }}
        </v-btn> -->
        
        </v-col
      >
    </v-row>

    <!-- Kids Cards -->
    <v-row class="kids">
      <v-col
        cols="6"
        sm="3"
        class="py-0 mb-4"
        v-for="(student, index) in studentInfo"
        :key="index"
      >
        <v-row
          class="kid"
          :class="student.active == true ? 'active' : ''"
          @click="MakeHimActive(index)"
        >
          <v-col cols="4">
            <img
              :src="student.logo"
              v-if="student.logo"
              alt="student image"
              class="student-image"
            />
            <img
              v-else
              src="../../assets/avatar.png"
              class="student-image"
              alt=""
            />
          </v-col>
          <v-col cols="8">
            <p class="student-name">{{ student.name }}</p>
          </v-col>
          <v-col cols="4" class="py-0">
            <p class="student-grade">{{ student.grade }}</p>
          </v-col>

          <v-col cols="8" class="py-0">
            <p class="text-right student-class">{{ student.class }}</p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- accordion for students -->
    <div v-for="(year, idx) in quartersDetails" :key="idx">
      <h2 class="mt-6 main-color">
        {{ year.academic_period_name }} - {{ year.grade_name }}
      </h2>
      <v-expansion-panels v-model="panels[idx].panel">
        <v-expansion-panel
          v-for="(item, index) in year.quarters"
          :key="index"
          @click="fetchSubjects(idx, year.academic_grade_id, item.id)"
        >
          <v-expansion-panel-header>
            <div class="d-flex justify-space-between">
              <p class="mb-0">Quarters {{ item.name }}</p>
              <v-btn @click="printCard(year.academic_grade_id, item.id)" class="main-color-back mb-0" v-if="is_active && panels[idx].panel == index">
                <v-icon :title="$t('Print')">print</v-icon>
                {{$t('Report Card')}}
              </v-btn>
             
              <p class="mb-0" v-if="is_active && panels[idx].panel == index">
                {{ $t("Total Mark") }}: {{ total_mark }}
              </p>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-simple-table
              class="elevation-1 level1"
              v-if="
                activeQuarter == item.id &&
                quarterData &&
                quarterData.length > 0
              "
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      {{ $t("Subject") }}
                    </th>
                    <th class="text-left">
                      {{ $t("Mark") }}
                    </th>
                    <th class="text-left">
                      {{ $t("Details") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in quarterData" :key="index">
                    <td>{{ item.subject_name }}</td>
                    <td>
                      <p
                        class="passed mb-0"
                        v-if="
                          item &&
                          item.passing_mark &&
                          Number(item.student_mark) >= Number(item.passing_mark)
                        "
                      >
                        {{ item.mark_display }}
                      </p>
                      <p
                        class="faild mb-0"
                        v-if="
                          item &&
                          item.passing_mark &&
                          Number(item.student_mark) < Number(item.passing_mark)
                        "
                      >
                        {{ item.mark_display }}
                      </p>
                      <p v-if="!item.passing_mark" class="unAssignedMark mb-0">
                        {{ item.mark_display }}
                      </p>
                    </td>

                    <td>
                      <span
                        v-for="(q, index) in item.qualifiers"
                        :key="index"
                        class="student_qualifier"
                      >
                        {{ q.qualifier_title }} {{ q.student_mark }} /
                        {{ q.qualifier_total }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <p v-else>{{ msg }}</p>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "parentVeiw",
  data() {
    return {
      msg: this.$i18n.t("Loading Data ..."),
      studentInfo: [],
      selectedStudent_id: "",
      panels: [],
      quartersDetails: [],
      quarterData: [],
      activeQuarter: 0,
      academic_grade_id: "",
      is_active:false,
      total_mark:"",
    };
  },
  methods: {
    MakeHimActive(index) {
      if (this.studentInfo[index].active != true) {
        this.studentInfo.forEach((student) => {
          student.active = false;
        });
        $(".kid").removeClass("active");
        this.studentInfo[index].active = true;
        $(".kid").eq(index).addClass("active");
        this.selectedStudent_id = this.studentInfo[index].student_id;
        this.getQuartersDetails();
        // this.filter.student_ids = [];
        // this.filter.student_ids.push(this.selectedStudent_id);
      } else {
        // this.filter.student_ids = [];
        // this.filter.student_ids.push(this.selectedStudent_id);
      }
    },
    getMyChildsInfo() {
      axios
        .get(this.getApiUrl + "/dashboard/parent", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.studentInfo = response.data.data.student_info;

          this.studentInfo.forEach((student) => {
            student.active = false;
          });
          this.studentInfo[0].active = true;
          this.selectedStudent_id = this.studentInfo[0].student_id;
          this.MakeHimActive(0);

          this.getQuartersDetails();
        });
    },

    getQuartersDetails() {
      axios
        .get(
          this.getApiUrl +
            "/educational-history/student/quarters?student_id=" +
            this.selectedStudent_id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.quartersDetails = response.data.data;

          this.quartersDetails.forEach((item, index) => {
            let obj = { panel: "" };

            this.panels.push(obj);
          });

          this.panels[0].panel = 0;
          this.fetchSubjects(
            0,
            this.quartersDetails[0].academic_grade_id,
            this.quartersDetails[0].quarters[0].id
          );
        });
    },

    fetchSubjects(yearIndx, academic_grade_id, quarter_id) {
      setTimeout(() => {
        console.log(yearIndx, academic_grade_id);

        if (
          this.panels[yearIndx].panel !== "" &&
          this.panels[yearIndx].panel !== undefined
        ) {
          this.activeQuarter = "";
          this.quarterData = [];
          this.panels.forEach((panel, index) => {
            if (index != yearIndx) {
              panel.panel = "";
            }
          });
          this.msg = this.$i18n.t("Loading Data ...");
          axios
            .get(
              this.getApiUrl +
                "/educational-history/student/quarters/subjects/" +
                quarter_id +
                "?academic_grade_id=" +
                academic_grade_id +
                "&student_id=" +
                this.selectedStudent_id,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              }
            )
            .then((response) => {
              this.is_active = response.data.data.is_active;
              this.total_mark = response.data.data.student_mark;
              if (response.data.data.is_active == true) {
                this.activeQuarter = response.data.data.quarter_id;
                this.is_active = response.data.data.is_active;
                this.quarterData = response.data.data.subjects;

                if (this.quarterData && this.quarterData.length == 0) {
                  this.msg = this.$i18n.t("No data available");
                }
              } else {
                this.msg = this.$i18n.t("No data available");
              }
            });
        }
      }, 200);
    },

    print() {
      axios
        .get(
          this.getApiUrl +
            "/educational-history/student/quarters/subjects/print?student_id=" +
            this.selectedStudent_id,

          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.pdfUrl = response.data.data;
          window.open(this.pdfUrl, "_blank");
        });
    },
    printCard(academic_grade_id, quarter_id) {
      axios
        .get(
          this.getApiUrl +
          "/educational-history/student/quarters/subjects/printCard?student_id=" +
          this.selectedStudent_id + "&academic_grade_id=" + academic_grade_id + '&quarter_id=' + quarter_id,

          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.pdfUrl = response.data.data;
          window.open(this.pdfUrl, "_blank");
        });
    },
    printDetailes() {
      axios
        .get(
          this.getApiUrl +
          "/educational-history/student/quarters/subjects/printDetailes?student_id=" +
          this.selectedStudent_id,

          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.pdfUrl = response.data.data;
          window.open(this.pdfUrl, "_blank");
        });
    },
  },
  mounted() {
    this.getMyChildsInfo();
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_forms.scss";

.passed {
  color: green;
}
.faild {
  color: red;
}
.unAssignedMark {
}

.student_qualifier {
  display: inline-block;
  margin: 0 15px;
}

.kids {
  margin-bottom: 20px;
  .kid {
    border: 1px solid #dddddd;
    background-color: #ffffff;
    border-radius: 10px;
    cursor: pointer;

    .student-image {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
    .student-name {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      color: #000000;
    }
    .student-grade,
    .student-class {
      color: #000000;
      margin-left: 10px;
    }
  }
  .kid.active {
    background-color: #d8e2ff;
    cursor: default;
  }
}
.main-color-back {
  background-color: #7297ff !important;
  border-color: #7297ff !important;
  color: #f7f7ff !important;
  margin-right: 20px !important;
}
.text-left {
  text-align: left !important;
}
</style>
